<template>
  <v-container grid-list-md>
    <v-layout>
      <v-flex xs12 sm3>
        <h1>Pontuação geral</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm3>
        <store-selector-component v-model="location"></store-selector-component>
      </v-flex>
      <v-flex xs12 sm3>
        <location-group-selector-component
          v-model="locationGroup"
        ></location-group-selector-component>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm7>
        <user-score-component
          v-for="(user, index) in users"
          :IsFirst="index"
          :Order="++index"
          :key="user.code"
          :user="user"
        ></user-score-component>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import jobLocationsComponent from "@/components/selectors/job-locations";
import jobLocationGroupsComponent from "@/components/selectors/job-location-groups";
import usersService from "@/services/api/usersService";
import UserScoreComponent from "@/components/gamification/user-score";
export default {
  components: {
    "store-selector-component": jobLocationsComponent,
    "location-group-selector-component": jobLocationGroupsComponent,
    "user-score-component": UserScoreComponent,
  },
  data() {
    return {
      location: null,
      locationGroup: null,
      users: [],
    };
  },
  watch: {
    location: async function () {
      await this.loadUsers();
    },
    locationGroup: async function () {
      await this.loadUsers();
    },
  },
  methods: {
    async loadUsers() {
      let data = {
        PageId: 1,
        PageSize: 10,
        LoadTotal: false,

        Filters: [
          { Field: "IdUserRole", Operation: "eq", Value: 1 },
          { Field: "GamificationPoints", Operation: "gt", Value: 0 },
        ],
        Order: [{ Field: "GamificationPoints", Direction: "desc" }],
      };
      if (this.location) {
        data.Filters.push({
          Field: "IdJobLocation",
          Operation: "eq",
          Value: this.location,
        });
      }
      if (this.locationGroup) {
        data.Filters.push({
          Field: "IdJobLocationGroup",
          Operation: "eq",
          Value: this.locationGroup,
        });
      }

      await usersService
        .Get(data)
        .then((response) => (this.users = response.data.Items));
    },
  },
  async created() {
    await this.loadUsers();
  },
};
</script>

<style scoped></style>
