<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 sm3>
        <store-selector-component v-model="location"></store-selector-component>
      </v-flex>
      <v-flex xs12 sm3>
        <location-group-selector-component
          v-model="locationGroup"
        ></location-group-selector-component>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-card>
          <Grid
            :style="{ height: 'auto' }"
            :data-items="Items"
            :columns="Columns"
            :pageable="true"
            :skip="skip"
            :take="take"
            :total="grid.pagging.total"
            @pagechange="pageChange"
            :sortable="false"
            :filterable="false"
          >
            <GridNoRecords>Não existe histórico</GridNoRecords>
          </Grid>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import instancesService from "@/services/api/usersService";
import GridHelper from "@/utilities/grid";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import jobLocationsComponent from "@/components/selectors/job-locations";
import jobLocationGroupsComponent from "@/components/selectors/job-location-groups";
export default {
  components: {
    "store-selector-component": jobLocationsComponent,
    "location-group-selector-component": jobLocationGroupsComponent,
  },
  data() {
    return {
      location: null,
      locationGroup: null,
      Instance: {},
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Items: [],
      Columns: [
        {
          field: "Date",
          title: "Data",
          width: 150,
          sortable: false,
        },
        {
          field: "Type",
          title: "Tipo",
          width: 200,
          sortable: false,
        },
        {
          field: "Notes",
          title: "Descrição",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    location: async function () {
      await this.readUserLogs();
    },
    locationGroup: async function () {
      await this.readUserLogs();
    },
  },
  computed: {
    ...mapGetters({ user: types.GET_USER }),
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  async created() {
    await this.readUserLogs();
  },
  methods: {
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readUserLogs();
    },
    async readUserLogs() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      data.Filters = [
        ...data.Filters,
        {
          Field: "GamificationAcitivities",
          Operation: "eq",
          Value: "GamificationAcitivities",
        },
      ];
      data.Order = [{ Field: "Date", Direction: "DESC" }];
      if (this.location) {
        data.Filters.push({
          Field: "IdJobLocation",
          Operation: "eq",
          Value: this.location,
        });
      }
      if (this.locationGroup) {
        data.Filters.push({
          Field: "IdJobLocationGroup",
          Operation: "eq",
          Value: this.locationGroup,
        });
      }

      await instancesService
        .GetUserLogs(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    reloadGrid() {
      this.datasource.read();
    },
  },
};
</script>

<style></style>
