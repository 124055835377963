import Vue from "vue";
import { VRadioGroup, VRadio } from "vuetify/lib";

export default Vue.component("bool-filter-component", {
  components: {
    "v-radio-group": VRadioGroup,
    "v-radio": VRadio,
  },
  props: {
    field: String,
    filterType: String,
    value: [String, Number, Boolean, Date],
    operator: String,
  },
  data() {
    return {
      commitedValue: null,
      currentOperator: "eq",
      currentValue: null,
    };
  },
  created() {
    this.currentValue = this.value;
  },
  template: `<div>
                 <v-radio-group v-model="currentValue" :mandatory="false" @change='change'>
                  <v-radio label="Sim" :value="true"></v-radio>
                  <v-radio label="Não" :value="false"></v-radio>
                </v-radio-group>
                <button v-if="currentValue != null" @click="reset" class="k-button k-button-icontext grid-cell-filter__action"><span class="icon-close"></span></button>
            </div>`,
  methods: {
    change(ev) {
      if (this.commitedValue != this.currentValue) {
        this.commitedValue = this.currentValue;
        this.$emit("change", {
          operator: this.currentOperator,
          field: this.field,
          value: this.currentValue,
          syntheticEvent: ev,
        });
      }
    },
    reset(ev) {
      this.currentValue = null;
      this.$emit("change", {
        operator: "",
        field: "",
        value: "",
        syntheticEvent: ev,
      });
    },
  },
});
