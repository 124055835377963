<template>
  <v-container grid-list v-if="user">
    <h4 class="text-uppercase text--darkgray mb-4">Ausências</h4>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        class-icon="icon-add"
        :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Ausência`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error && Instance.TypeId > 0"
        ref="modal"
      >
        <v-layout wrap>
          <v-flex xs12 pr-2>
            <v-select
              v-model="Instance.TypeId"
              :items="UserAbsenceTypes"
              label="Tipo"
              item-text="Name"
              item-value="TypeId"
              required
            ></v-select>
          </v-flex>
          <v-flex xs6 pr-2>
            <app-date-picker
              v-model="Instance.DateStart"
              title="Data Início"
              required
            ></app-date-picker>
          </v-flex>
          <v-flex xs6 pl-2>
            <app-date-picker
              v-model="Instance.DateEnd"
              title="Data Fim"
              required
            ></app-date-picker>
          </v-flex>
        </v-layout>
      </app-form-modal>
    </div>
    <Grid
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :pageable="true"
      :skip="skip"
      :take="take"
      :total="grid.pagging.total"
      @pagechange="pageChange"
      :sortable="false"
      :filterable="false"
      @edit="edit"
      @remove="remove"
    >
      <template slot="typeCell" slot-scope="{ props }">
        <td :class="props.className">
          {{ getTypeName(props.dataItem) }}
        </td>
      </template>
      <GridNoRecords>Não existe histórico</GridNoRecords>
    </Grid>
  </v-container>
</template>
<script>
import * as kendo from "@progress/kendo-ui";
import { required } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import userAbsencesService from "@/services/api/userAbsencesService";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import DatePickerComponent from "@/components/forms/date-picker";
import GridHelper from "@/utilities/grid";
import { UserAbsenceTypes, GetTypeNameById } from "@/models/user-absence-types";
import Dates from "@/utilities/dates";

export default {
  components: {
    "app-date-picker": DatePickerComponent,
    "app-form-modal": FormModalComponent,
  },
  props: { user: { type: Object, default: null } },
  data() {
    return {
      UserAbsenceTypes,
      Instance: {},
      Items: [],
      grid: {
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Columns: [
        {
          field: "TypeId",
          title: "Motivo",
          cell: "typeCell",
        },
        {
          field: "DateStart",
          title: "Data início",
        },
        {
          field: "DateEnd",
          title: "Data de fim",
        },
        {
          cell: EditCommandCell,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      TypeId: {
        required,
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IsUserAbsence == 0;
    },
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  async created() {
    this.resetInstance();
    await this.loadItems();
  },
  methods: {
    getTypeName(item) {
      return GetTypeNameById(item.TypeId);
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readUserLogs();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      data.Filters = [
        ...data.Filters,
        { Field: "IdUser", Operation: "eq", Value: this.user.IdUser },
      ];
      await userAbsencesService
        .Get(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      var dt = new Date();
      this.Instance = {
        IdUserAbsence: 0,
        DateStart: Dates.PresentToDate(dt),
        DateEnd: Dates.PresentToDate(dt),
        TypeId: 0,
        IdUser: this.user.IdUser,
      };
    },
    async saveInstance(callBack) {
      if (this.Instance.IdUserAbsence == 0) {
        await userAbsencesService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a ausência com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await userAbsencesService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a ausência com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdUserAbsence == this.Instance.IdUserAbsence
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdUserAbsence;
      kendo.confirm("Tem a certeza que quer apagar a ausência?").done(() => {
        userAbsencesService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a ausência com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
};
</script>

<style scoped></style>
