<template>
  <div>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        class-icon="icon-add"
        :title-dialog="`${IsNew ? 'Adicionar' : 'Editar'} Badge`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error && Instance.Title.length > 0"
        ref="modal"
      >
        <v-layout row wrap v-if="this.IsNew">
          <v-flex xs12>
            <v-text-field
              v-model="Instance.Title"
              :error="$v.Instance.Title.$error"
              @blur="$v.Instance.Title.$touch()"
              label="Nome do Badge"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-label>Imagem</v-label>
            <kendo-upload
              ref="upload"
              :multiple="false"
              name="file"
              :validation-allowed-extensions="['.png', '.jpg', '.jpeg']"
              :select="onSelectFile"
            ></kendo-upload>
          </v-flex>
          <v-flex xs12>
            <v-radio-group v-model="Instance.IsActive" :mandatory="true">
              <label>Activo?</label>
              <v-radio label="Sim" :value="true"></v-radio>
              <v-radio label="Não" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              type="number"
              v-model="Instance.PointsStart"
              label="Pontos iniciais"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              type="number"
              v-model="Instance.PointsEnd"
              label="Pontos finais"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model="Instance.Description"
              label="Descrição"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="!this.IsNew">
          <v-flex xs12>
            <v-text-field
              v-model="Instance.Title"
              :error="$v.Instance.Title.$error"
              @blur="$v.Instance.Title.$touch()"
              label="Nome do Badge"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-label>Imagem</v-label>
            <kendo-upload
              ref="upload"
              :multiple="false"
              name="file"
              :validation-allowed-extensions="['.png', '.jpg', '.jpeg']"
              :select="onSelectFile"
            ></kendo-upload>
          </v-flex>
          <v-flex xs12>
            <v-radio-group v-model="Instance.IsActive" :mandatory="true">
              <label>Activo?</label>
              <v-radio label="Sim" :value="true"></v-radio>
              <v-radio label="Não" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              type="number"
              v-model="Instance.PointsStart"
              label="Pontos iniciais"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              type="number"
              v-model="Instance.PointsEnd"
              label="Pontos finais"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model="Instance.Description"
              label="Descrição"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </app-form-modal>
    </div>
    <Grid
      :style="{ height: this.dataItem != null ? 'auto' : '600px' }"
      :data-items="Items"
      :columns="Columns"
      @edit="edit"
      @remove="remove"
    >
      <template slot="photoCell" slot-scope="{ props }">
        <td :class="props.className">
          <img
            v-if="props.dataItem.PhotoSrc"
            :src="
              GetRelativePath(props.dataItem.PhotoSrc, 'w=75&h=75&mode=crop')
            "
            class="rounded--circle img-fluid"
          />
          <img
            v-else
            src="/assets/imgs/no-photo.png?w=75&h=75&mode=crop"
            class="rounded--circle img-fluid"
          />
        </td>
      </template>
      <GridNoRecords> Não existem anúncios </GridNoRecords>
    </Grid>
  </div>
</template>

<script>
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import GamificationService from "@/services/api/gamificationService";
import FormModalComponent from "@/components/modals/form-modal-large";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import UploadHelper from "@/utilities/upload";
import UrlBuilder from "@/utilities/url-builder";

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  props: ["dataItem"],
  data() {
    return {
      Instance: {
        IdGamification: 0,
        Title: "",
        Description: "",
        Photo: null,
        PointsStart: 0,
        PointsEnd: 0,
        IsActive: true,
      },
      expandedItems: [],
      Items: [],
      Columns: [
        { field: "PhotoSrc", title: "Foto", cell: "photoCell", width: 100 },
        { field: "Title", title: "Título" },
        { field: "Description", title: "Descrição" },
        { field: "PointsStart", title: "Pontos Iniciais" },
        { field: "PointsFinal", title: "Pontos Finais" },
        { field: "IsActive", title: "Activo" },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Title: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    IsNew() {
      return this.Instance != null && this.Instance.IdGamification == 0;
    },
  },
  methods: {
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    onSelectFile(evt) {
      UploadHelper.LoadFile(evt)
        .then((file) => (this.Instance.Photo = file))
        .catch((file) => (this.Instance.Photo = file));
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callBack) {
      this.resetInstance();
      callBack(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    resetInstance() {
      this.Instance = {
        IdGamification: 0,
        Title: "",
        Description: "",
        Photo: null,
      };
    },
    async loadItems() {
      let options = {
        Filters: [],
        Order: [],
      };
      await GamificationService.Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await GamificationService.Create(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou o anúncio com sucesso!"
            );
            this.Items.push(response.data.Instance);
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await GamificationService.Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o anúncio com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdGamification == this.Instance.IdGamification
            );
            if (index >= 0) {
              this.Items[index] = response.data.Instance;
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdGamification;
      kendo.confirm("Tem a certeza que quer apagar o anúncio?").done(() => {
        GamificationService.Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o anúncio com sucesso!"
            );
            this.Items = this.Items.filter((s) => s.IdGamification !== id);
            this.resetInstance();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  async created() {
    this.resetInstance();
    await this.loadItems();
  },
  mounted() {},
};
</script>

<style></style>
