var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"my-5"},[_c('v-card-text',{},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs2":""}},[_c('img',{staticClass:"img-circle rounded img-fluid",attrs:{"src":_vm.user.PhotoSrc != null
              ? _vm.GetRelativePath(_vm.user.PhotoSrc, 'w=100&h=100&mode=crop')
              : _vm.GetRelativePath(_vm.user.Photo, 'w=100&h=100&mode=crop'),"alt":"foto user","title":"foto user"}})]),_c('v-flex',{attrs:{"xs8":""}},[_c('v-layout',{staticClass:"ml-4",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs8":""}},[_c('p',{staticClass:"text-uppercase mb-2"},[_vm._v(" "+_vm._s(_vm.user.Name)+" ")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('ul',{staticClass:"list-unstyled-badges list-inline mb-0"},_vm._l((_vm.badges),function(badge){return _c('li',{key:badge.Title,staticClass:"list-inline-item mr-5"},[(_vm.user.GamificationPoints > badge.PointsStart)?_c('div',{staticClass:"c-tooltip d-inline-block"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('img',_vm._g({staticClass:"img-fluid rounded",attrs:{"src":_vm.GetRelativePath(
                            badge.PhotoSrc,
                            'w=45&h=45&mode=crop'
                          )}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(badge.Title))])])],1):_vm._e()])}),0)])],1)],1),_c('v-flex',{attrs:{"xs2":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.Order),expression:"Order"}],staticClass:"text-right"},[_c('p',{staticClass:"size--s12"},[_vm._v("Classificação")]),_c('p',{staticClass:"size--s40 font-weight-bold",class:{
              points: _vm.IsFirst === 0,
            }},[_vm._v(" "+_vm._s(_vm.user.GamificationPoints)+" ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }