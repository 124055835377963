/* eslint-disable prettier/prettier */
<template>
  <section id="UsersIndex">
    <app-page-title>
      <v-layout row justify-space-between align-end>
        <v-flex>
          <h1>Listagem de Utilizadores</h1>
          <h3 v-if="role" class="size--s28">{{ role.Name }}</h3>
          <app-selector-usergroups class="mr-2" v-model="filters.IdUserGroup"></app-selector-usergroups>
          <!-- <app-selector-locations class="mr-2" v-model="filters.IdJobLocation">
          </app-selector-locations> -->
          <app-selector-functions class="mr-2" v-model="filters.IdJobFunction">
          </app-selector-functions>
          <span class="custom-checkbox mr-2 white--text">
            <v-checkbox v-model="filters.IsActive" label="Activo" color="accent" class="white--text" dark></v-checkbox>
          </span>
          <v-btn color="grey lighten-2 mr-2" @click="loadItems()">
            <span class="text-uppercase size--s14 font-weight-light">Filtrar</span>
            <i class="icon-filter ml-2" />
          </v-btn>
        </v-flex>
        <v-flex class="text-right">
          <app-form-modal ref="modal" class-icon="icon-add" :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Utilizador`" :confirmAction="saveInstance" :cancelAction="clearData" :formValid="!$v.Instance.$error && Instance.Code.length > 0">
            <div class="form-group">
              <v-text-field v-model="Instance.Code" :error="$v.Instance.Code.$error" @blur="$v.Instance.Code.$touch()" label="Código do utilizador" required></v-text-field>
              <v-text-field v-model="Instance.Name" :error="$v.Instance.Name.$error" @blur="$v.Instance.Name.$touch()" label="Nome" required></v-text-field>
              <v-text-field v-model="Instance.FullName" label="Nome Completo" required></v-text-field>
              <v-text-field v-model="Instance.Email" @blur="$v.Instance.Email.$touch()" label="Email do utilizador"></v-text-field>
              <v-text-field v-model="Instance.FiscalIdNumber" label="NIF" required></v-text-field>
              <v-select item-text="Name" :items="userRoles" v-model="Instance.IdUserRole" item-value="IdUserRole" label="Role do utilizador" required></v-select>
              <v-select v-model="Instance.IdUserGroup" :items="userGroups" label="Grupo Principal" item-text="Name" item-value="IdUserGroup" :clearable="true" :required="false"></v-select>
            </div>
          </app-form-modal>
        </v-flex>
      </v-layout>
    </app-page-title>
    <v-container>
      <Grid :style="{ height: 'auto' }" :data-items="Items" :columns="Columns" :pageable="true" :skip="skip" :take="take" :total="grid.pagging.total" @pagechange="pageChange" :sortable="true" :sort="grid.sort" @sortchange="sortChange">
        <template slot="locationCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ locationTemplate(props.dataItem) }}
          </td>
        </template>
        <template slot="departmentCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ departmentTemplate(props.dataItem) }}
          </td>
        </template>
        <template slot="typeIdCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ departmentTemplate(props.dataItem) }}
          </td>
        </template>
        <template slot="linkCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link :to="`/users/${props.dataItem.IdUser}`">
              {{ props.dataItem[props.field] }}
            </router-link>
          </td>
        </template>
        <template slot="detailCell" slot-scope="{ props }">
          <td :class="props.className">
            <router-link class="k-button k-button-icontext" :to="`/users/${props.dataItem.IdUser}/edit`">
              <span class="icon-mode_edit"></span>
            </router-link>
          </td>
        </template>
        <template slot="removeCell" slot-scope="{ props }">
          <td :class="props.className">
            <button class="k-button k-button-icontext" @click="rowRemoveHandler(props.dataItem)">
              <span class="icon-close"></span>
            </button>
          </td>
        </template>
        <template slot="boolCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ props.dataItem[props.field] ? "Sim" : "Não" }}
          </td>
        </template>
        <GridNoRecords>Não existem utilizadores</GridNoRecords>
      </Grid>
    </v-container>
  </section>
</template>

<script>
/* eslint-disable */
import * as types from "@/store/types";
import {
  mapGetters
} from "vuex";
import {
  required,
  minLength,
  email
} from "vuelidate/lib/validators";
import {
  notificationServiceBus
} from "@/services/bus/notificationServiceBus";
import FormModalComponent from "@/components/modals/form-modal";
import usersService from "@/services/api/usersService";
import GridHelper from "@/utilities/grid";
import StringFilterCell from "@/components/grid/string-filter.js";
import BoolFilterCell from "@/components/grid/bool-filter.js";
import router from "@/router/router";
import UserGroupsSelectorComponent from "@/components/selectors/user-groups";
import JobFunctionSelectorComponent from "@/components/selectors/job-functions-dropdown";
import * as kendo from "@progress/kendo-ui";
export default {
  components: {
    "app-form-modal": FormModalComponent,
    "app-selector-usergroups": UserGroupsSelectorComponent,
    "app-selector-functions": JobFunctionSelectorComponent,
  },
  data () {
    return {
      filters: {
        IdJobLocation: null,
        IdJobFunction: null,
        IsActive: true,
      },
      role: null,
      Instance: {
        IdUser: 0,
        Name: "",
        Code: "",
        Email: "",
        IdUserRole: 1,
      },
      grid: {
        sort: [],
        filter: {
          logic: "and",
          filters: [],
        },
        pagging: {
          pageId: 1,
          pageSize: 20,
          total: 0,
        },
      },
      JobLocations: [],
      JobDepartments: [],
      Items: [],
      Columns: [{
        field: "Code",
        title: "Código",
        width: 200,
        filterCell: StringFilterCell,
        cell: "linkCell",
      },
      {
        field: "Name",
        title: "Nome",
        filterCell: StringFilterCell,
        cell: "linkCell",
      },
      {
        field: "Location",
        title: "Localização",
        cell: "locationCell",
        sortable: false,
      },
      {
        field: "Department",
        title: "Departamento",
        cell: "departmentCell",
        sortable: false,
      },
      {
        field: "IsActive",
        title: "Ativo",
        sortable: false,
        cell: "boolCell",
        headerCell: this.noSortableFunction,
        filterCell: BoolFilterCell,
        width: 150,
      },
      {
        cell: "detailCell",
        filterable: false,
        sortable: false,
        width: 75,
        title: "",
      },
      {
        cell: "removeCell",
        filterable: false,
        sortable: false,
        width: 75,
        title: "",
      },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      Email: {
        email,
      },
      Code: {
        required,
        minLength: minLength(2),
      },
    },
  },
  computed: {
    ...mapGetters({
      userGroups: types.GET_USER_GROUPS,
      userRoles: types.GET_USER_ROLES,
    }),
    isNew () {
      return this.Instance.IdUser === 0;
    },
    skip () {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take () {
      return this.grid.pagging.pageSize;
    },
  },
  watch: {
    $route () {
      if (this.$route.query.role != this.Instance.IdUserRole) {
        this.Instance.IdUserRole = parseInt(this.$route.query.role);
        this.loadRole();
        this.loadItems();
      }
      if (this.$route.query.role == null) {
        this.role = null;
      }
      if (location.hash) {
        this.$refs.modal.open();
      }
    },
  },
  methods: {
    locationTemplate (e) {
      return e.UserPositions.length > 0 ?
        e.UserPositions[0].Location :
        "Não definido";
    },
    async rowRemoveHandler (dataItem) {
      kendo.confirm("Tem a certeza que quer apagar pergunta?").done(() => {
        usersService
          .Delete(dataItem.IdUser)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a resposta com sucesso!"
            );
          })
          .catch((error) => {
            this.isLoading = false;
            notificationServiceBus.showError(error.response);
          });
      });
    },
    departmentTemplate (e) {
      return e.UserPositions.length > 0 ?
        e.UserPositions[0].Department :
        "Não definido";
    },
    noSortableFunction: function (h, tdElement, props) {
      return h("span", [props.title]);
    },
    clearData (callBack) {
      this.resetInstance();
      callBack(true);
    },
    filterChange (ev) {
      this.grid.pagging.pageId = 1;
      this.grid.filter = ev.filter;
      this.loadItems();
    },
    sortChange (ev) {
      this.grid.sort = ev.sort;
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
    pageChange (ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadItems () {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        this.grid.filter,
        this.grid.sort
      );
      if (this.filters.IdUserGroup) {
        options.Filters.push({
          Field: "IdUserGroup",
          Operation: "eq",
          Value: this.filters.IdUserGroup,
        });
      }
      if (this.filters.IdJobLocation) {
        options.Filters.push({
          Field: "IdJobLocation",
          Operation: "eq",
          Value: this.filters.IdJobLocation,
        });
      }
      if (this.filters.IdJobDepartment) {
        options.Filters.push({
          Field: "IdJobDepartment",
          Operation: "eq",
          Value: this.filters.IdJobDepartment,
        });
      }
      if (this.filters.IdJobFunction) {
        options.Filters.push({
          Field: "IdJobFunction",
          Operation: "eq",
          Value: this.filters.IdJobFunction,
        });
      }
      options.Filters.push({
        Field: "IsActive",
        Operation: "eq",
        Value: this.filters.IsActive,
      });
      if (this.$route.query.role) {
        options.Filters.push({
          Field: "IdUserRole",
          Operation: "eq",
          Value: this.$route.query.role,
        });
      }
      await usersService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance () {
      this.Instance = {
        IdCourse: 0,
        Code: "",
        Name: ""
      };
    },
    async saveInstance (callBack) {
      await usersService
        .Create(this.Instance)
        .then((response) => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Criou o portfolio com sucesso!"
          );
          this.resetInstance();
          callBack(true);
          router.push(`/users/${response.data.Instance.IdUser}/`);
        })
        .catch((error) => {
          callBack(false);
          notificationServiceBus.showError(error.response);
        });
    },
    groupColumnTemplate (e) {
      if (!e.IdUserGroup && !this.userGroups) {
        return "-";
      }
      const type = this.userGroups.find((s) => s.IdUserGroup === e.IdUserGroup);
      return type ? type.Name : "-";
    },
    gridUserGroupsFilter (element) {
      element.kendoDropDownList({
        dataSource: this.userGroups,
        dataTextField: "Name",
        dataValueField: "IdUserGroup"
      });
    },
    loadRole () {
      if (this.$route.query.role && this.userRoles) {
        const data = this.userRoles;
        for (var i = 0; i < data.length; i++) {
          if (data[i].IdUserRole == this.$route.query.role) this.role = data[i];
        }
      } else {
        this.role = null;
      }
    },
  },
  async created () {
    this.resetInstance();
    await this.loadItems();
    if (this.$route.query.role) {
      this.Instance.IdUserRole = parseInt(this.$route.query.role);
    }
    this.loadRole();
  },
  mounted () {
    if (location.hash) {
      this.$refs.modal.open();
    }
  },
};
</script>

<style lang="scss" scoped>
// #UsersIndex .page-title .k-dropdown {
//   min-width: 300px !important;
// }
</style>
/* eslint-enable prettier/prettier */