<template>
  <v-container>
    <app-badges-grid></app-badges-grid>
  </v-container>
</template>

<script>
import BadgesGrid from "./grid";

export default {
  components: {
    "app-badges-grid": BadgesGrid,
  },
};
</script>

<style scoped></style>
