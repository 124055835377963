<template>
  <section>
    <app-page-title>
      <h1>Gamification</h1>
    </app-page-title>
    <div class="d-flex align-content-stretch detail-wrapper">
      <div class="detail-sidebar">
        <app-sidebar-menu :menus="menus" />
      </div>
      <div class="detail-content">
        <router-view params="route: route" />
      </div>
    </div>
  </section>
</template>

<script>
import SidebarComponent from "@/components/layout/page-menu";

export default {
  components: {
    "app-sidebar-menu": SidebarComponent,
  },
  data() {
    return {
      menus: [
        {
          Name: "Dashboard",
          Icon: "icon-home",
          Path: "dashboard",
        },
        {
          Name: "Gestão de Badges",
          Icon: "icon-catalog",
          Path: "badges",
        },
        {
          Name: "Log de Actividades",
          Icon: "icon-users",
          Path: "logs",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
