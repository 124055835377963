<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :clearable="true"
    item-text="Description"
    item-value="IdUser"
    :label="label"
    :placeholder="placeholder"
    @change="onChanged"
  ></v-autocomplete>
</template>
<script>
import usersService from "@/services/api/usersService";

export default {
  props: ["parent"],
  data() {
    return {
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      placeholder: this.parent ? this.parent.Name : "Começe a digitar",
    };
  },
  computed: {
    label() {
      return this.parent ? "Gestor: " + this.parent.Name : "Gestor";
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description = `${entry.Code} » ${entry.Name}`;
        return Object.assign({}, entry, { Description });
      });
    },
  },
  watch: {
    async search(val) {
      if (!val || val.length < 3) {
        this.entries = [];
        this.count = 0;
        return;
      }

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      await usersService
        .Get({ Filters: [{ Field: "Search", Operation: "eq", Value: val }] })
        .then((res) => {
          this.count = res.data.Total;
          this.entries = res.data.Items;
        })
        .catch(() => {
          this.count = 0;
          this.entries = [];
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    onChanged() {
      this.$emit("userSelected", this.model);
    },
  },
};
</script>
