<template>
  <router-link
    :to="`/catalog/courses/${course.IdCourse}`"
    class="p-4 white timeline__box border-left mb-3"
    :class="borderClass"
    v-if="course"
  >
    <v-layout align-center>
      <v-flex xs2>
        <div class="timeline__box--icon">
          <i class="size--s36" :class="iconClass"></i>
        </div>
      </v-flex>
      <v-flex xs7>
        <p class="font-weight-light size--s14 text-uppercase mb-2">
          {{ course.Name }}
        </p>
        <div>
          <v-chip color="info" text-color="white" class="mr-2">{{
            status
          }}</v-chip>
          <v-chip color="accent" text-color="white">{{
            courseTypeStatus
          }}</v-chip>
          <p v-if="subscription" class="size--s10 mt-2">
            {{ subscription.Minutes }} minutos decorridos
          </p>
        </div>
      </v-flex>
      <v-flex xs3 class="text-right" align-self-end v-if="label">
        <v-chip color="light-green" text-color="white">{{ label }}</v-chip>
      </v-flex>
    </v-layout>
  </router-link>
</template>
<script>
import { GetTypeNameById } from "@/models/course-types";

export default {
  props: ["course", "user", "label"],
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    iconClass() {
      if (this.subscription) {
        if (this.subscription.IsActive) {
          return "icon-settings-portfolio-categories";
        } else if (this.subscription.IsCompleted) {
          return "icon-check";
        }
      }
      return "icon-alert";
    },
    borderClass() {
      if (this.subscription) {
        if (this.subscription.IsActive) {
          return "";
        } else if (this.subscription.IsCompleted) {
          return "b-green";
        }
      }
      return "b-red";
    },
    status() {
      if (this.subscription) {
        if (this.subscription.IsActive) {
          return "Em Formação";
        } else if (this.subscription.IsCompleted) {
          return "Finalizado";
        } else if (this.subscription.IsInitiated) {
          return "Não inciado";
        }
      }
      return "Não inciado";
    },
    courseTypeStatus() {
      return GetTypeNameById(this.course.TypeId);
    },
  },
  created() {
    if (this.user && this.user.Subscriptions) {
      this.subscription = this.user.Subscriptions.find(
        (s) => s.IdCourse === this.course.IdCourse
      );
    }
  },
};
</script>
