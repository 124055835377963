<template>
  <v-container>
    <h4 class="text-uppercase text--darkgray mb-4">Histórico Formativo</h4>
    <app-inscriptions-grid :user="user"></app-inscriptions-grid>
  </v-container>
</template>

<script>
import SubscriptionsGridComponent from "@/components/grid/subscriptions.grid";

export default {
  components: {
    "app-inscriptions-grid": SubscriptionsGridComponent,
  },
  props: ["user"],
};
</script>

<style scoped></style>
