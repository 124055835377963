<template>
  <v-card class="my-5">
    <v-card-text class="">
      <v-layout align-center>
        <v-flex xs2>
          <img
            :src="
              user.PhotoSrc != null
                ? GetRelativePath(user.PhotoSrc, 'w=100&h=100&mode=crop')
                : GetRelativePath(user.Photo, 'w=100&h=100&mode=crop')
            "
            alt="foto user"
            title="foto user"
            class="img-circle rounded img-fluid"
          />
        </v-flex>
        <v-flex xs8>
          <v-layout row wrap class="ml-4">
            <v-flex xs8>
              <p class="text-uppercase mb-2">
                {{ user.Name }}
              </p>
            </v-flex>
            <v-flex xs12>
              <ul class="list-unstyled-badges list-inline mb-0">
                <li
                  class="list-inline-item mr-5"
                  v-for="badge in badges"
                  :key="badge.Title"
                >
                  <div
                    class="c-tooltip d-inline-block"
                    v-if="user.GamificationPoints > badge.PointsStart"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <img
                          v-on="on"
                          :src="
                            GetRelativePath(
                              badge.PhotoSrc,
                              'w=45&h=45&mode=crop'
                            )
                          "
                          class="img-fluid rounded"
                        />
                      </template>
                      <span>{{ badge.Title }}</span>
                    </v-tooltip>
                  </div>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs2>
          <div class="text-right" v-show="Order">
            <p class="size--s12">Classificação</p>
            <p
              class="size--s40 font-weight-bold"
              v-bind:class="{
                points: IsFirst === 0,
              }"
            >
              {{ user.GamificationPoints }}
            </p>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import UrlBuilder from "@/utilities/url-builder";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import gamingService from "@/services/api/gamificationService";
export default {
  props: ["user", "IsFirst", "Order"],
  data() {
    return {
      badges: [],
      nextBadgeStart: 0,
      nextBadgeEnd: 0,
      remainingPoints: 0,
    };
  },
  methods: {
    GetRelativePath(path, query) {
      return `${UrlBuilder.GetPath(path)}${query ? "?" + query : ""}`;
    },
    loadBadges() {
      let options = {
        Filters: [],
        Order: [],
      };
      gamingService.Get(options).then((res) => {
        this.badges = res.data.Items;
        this.setScore();
      });
    },
    setScore() {
      for (let index = 0; index < this.badges.length; index++) {
        const element = this.badges[index];
        if (element.PointsFinal >= this.user.GamificationPoints) {
          this.nextBadgeEnd = element.PointsFinal;
          this.nextBadgeStart = element.PointsStart;
          this.remainingPoints =
            ((this.user.GamificationPoints - element.PointsStart) * 100) /
            (element.PointsFinal - element.PointsStart);
          break;
        }
      }
      // ((input - min) * 100) / (max - min)
      // this.nextBadgeStart = result["PointsStart"];
      // this.nextBadgeEnd = result["PointsFinal"];
      // badge.PointsFinal >= this.user.GamificationPoints
    },
  },
  computed: {
    ...mapGetters({
      currentUser: types.GET_USER,
    }),
  },
  created() {
    this.loadBadges();
  },
};
</script>
<style lang="scss">
.v-card__text {
  padding: 16px;
}
.list-unstyled-badges {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  .list-inline-item {
    text-align: center;
    display: inline-block;
  }
}
.points {
  color: #7ed321;
}
</style>
